<template>
  <section class="page-wrap">
    <!-- 输入框 start -->
    <div class="input-wrap">
      <el-input
        v-model="sheetOrder"
        placeholder="请在此输入或扫描供货单编码"
        ref="refSheetOrder"
        autofocus="true"
        @keyup.enter.native="onHandleSearch"
      >
        <el-button
          slot="suffix"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="onHandleSearch"
          >确定</el-button
        >
      </el-input>
    </div>
    <!-- 输入框 end -->

    <!-- 供货单布局 start -->
    <div v-if="isShowInfo" class="table-wrap" v-loading="loading">
      <div class="sheet-info">
        <div class="sheet-info__header">
          供货单号：{{ formData.supplierOrder }}
        </div>
        <div class="sheet-info__body">
          <!-- <div class="print__count" v-if="formData.print_ed_num">
            {{ `打印次数：${formData.print_ed_num}次` }}
          </div> -->
          <div class="info-one">供货日期：{{ formData.supplierDate }}</div>
          <div class="info-two">
            <div>供货商名称：{{ formData.supplierTitle }}</div>
            <div>联系人：{{ formData.supplierName }}</div>
            <div>联系电话：{{ formData.supplierMobile }}</div>
          </div>
          <div class="info-three">
            <div>SKU数量：{{ formData.sku }}</div>
            <div>总件数：{{ formData.productTotal }}件</div>
          </div>
          <div class="print">
            <div class="btn" @click="onHandlePrint">打印全部标签</div>
          </div>
        </div>
      </div>
      <div class="table-content">
        <table-list
          :tableData="tableData"
          @on-handle-print="onHandlePrint"
        ></table-list>
      </div>
    </div>
    <!-- 供货单布局 end -->

    <!-- 缺省 start -->
    <c-empty-page v-else tip="请扫描或输入供货单编号~"></c-empty-page>
    <!-- 缺省 end -->

    <!-- 打印窗口 start -->
    <b-select-windows
      ref="refBSelectWindows"
      @on-change-print-windows="onChangePrintWindows"
    ></b-select-windows>
    <!-- 打印窗口 end -->

    <!-- 打印份数弹窗 start -->
    <print-count-dialog ref="refPrintCountDialog"></print-count-dialog>
    <!-- 打印份数弹窗 end -->
  </section>
</template>

<script>
import TableList from "./modules/table-list";
import CEmptyPage from "@/components/common/CEmptyPage";
import BSelectWindows from "@/components/business/BSelectWindows";
import PrintCountDialog from "./modules/print-count-dialog/index.vue";
import {
  getGoodsSheetInfo,
  postPrintBatchOfOrder,
} from "@/api/sheet-to-review/index";

export default {
  name: "LogisticsTagPrint",
  data() {
    return {
      loading: false,
      // 供货单编号
      sheetOrder: "",
      windowId: "", // 打印窗口
      // 供货单详情
      formData: {
        supplierOrder: "",
        supplierDate: "",
        supplierTitle: "",
        supplierName: "",
        supplierMobile: "",
        sku: "",
        productTotal: "",
        print_ed_num: "", // 打印次数
      },
      tableData: [],
      // 是否展示打印详情
      isShowInfo: false,
    };
  },
  components: {
    TableList,
    CEmptyPage,
    BSelectWindows,
    PrintCountDialog,
  },
  mounted() {
    // this.onHandleSearch();
    // this.$refs.refBSelectWindows.onInitData();
    this.$refs.refSheetOrder.focus();
  },
  methods: {
    /**
     * 查询供货单相关
     */
    async onHandleSearch() {
      if (!this.sheetOrder) {
        this.$message.error("请扫描或输入供货单编号~");
        return;
      }
      this.loading = true;
      const params = {
        supplierOrderNo: this.sheetOrder,
      };
      try {
        const { data } = await getGoodsSheetInfo(params);
        const {
          supplier_order_no: supplierOrder,
          date: supplierDate,
          supplier_title: supplierTitle,
          supplier_fullname: supplierName,
          supplier_mobile: supplierMobile,
          sku,
          product_total_number: productTotal,
          print_ed_num,
          list,
        } = data;
        this.sheetOrder = "";
        this.formData = {
          supplierOrder,
          supplierDate,
          supplierTitle,
          supplierName,
          supplierMobile,
          print_ed_num,
          sku,
          productTotal,
        };
        this.tableData = list;
        this.isShowInfo = true;
        console.log("ajax getGoodsSheetInfo", this.formData);
      } catch (err) {
        console.log("ajax getGoodsSheetInfo err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 获取打印窗口
     */
    onChangePrintWindows(data) {
      this.windowId = data.windowId;
      const prePageParams = data.prePageParams;
      if (this.isShowInfo) {
        this.onHandlePrint(prePageParams);
      } else {
        this.$refs.refSheetOrder.focus();
      }
    },
    /**
     * 打印标签
     */
    onHandlePrint(data) {
      console.log("🆒 ajax onHandlePrint data", data);
      /** 未选择窗口 */
      if (!this.windowId) {
        this.$refs.refBSelectWindows.onInitData(data);
        return;
      }
      // 单个标签打印
      if (data.sku_code) {
        const params = {
          ...data,
          supplierOrder: this.formData.supplierOrder,
          windowId: this.windowId,
        };
        this.$refs.refPrintCountDialog.onInitData(params);
      } else {
        // 全部标签打印
        this.ajaxPostPrintBatchOfOrder();
      }
    },
    /**
     * 打印全部标签
     */
    async ajaxPostPrintBatchOfOrder() {
      try {
        const params = {
          supplier_order_no: this.formData.supplierOrder,
          window_id: this.windowId,
        };
        const { data } = await postPrintBatchOfOrder(params);
        console.log("ajax postPrintBatchOfOrder data", data);
        this.$message.success("打印成功");
      } catch (err) {
        console.log("ajax postPrintBatchOfOrder err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  .input-wrap {
    padding-bottom: 6px;
    /deep/ .el-input {
      position: relative;
      &__inner {
        font-size: 16px;
        height: 45px;
        line-height: 45px;
        &::placeholder {
          // font-size: 18px;
          padding-left: 10px;
        }
      }
      &__suffix {
        top: 5px;
        right: 20px;
      }
    }
    /deep/ .el-button {
      padding: 0 15px;
      height: 34px;
      .el-icon-search {
        font-size: 12px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .table-wrap {
    height: calc(100% - 60px);
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    .table-content {
      // max-height: 790px;
      height: calc(100% - 240px);
      overflow: auto;
    }
  }
  .sheet-info {
    &__header {
      padding-bottom: 20px;
      text-align: center;
      font-size: 22px;
      color: #333333;
    }
    &__body {
      font-size: 20px;
      .info-one {
        line-height: 35px;
        font-weight: 600;
      }
      .info-two,
      .info-three {
        display: flex;
        gap: 60px;
        line-height: 40px;
      }
      .print {
        padding-top: 20px;
        padding-bottom: 25px;
        .btn {
          padding: 8px 12px;
          cursor: pointer;
          border-radius: 4px;
          display: inline-block;
          font-size: 15px;
          color: #fff;
          background-color: #fa884c;
        }
      }
      .print__count {
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 600;
        color: $danger-color;
      }
    }
  }
}
</style>
