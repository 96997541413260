<template>
  <section class="modules-wrap">
    <!-- table start -->
    <div class="table">
      <div v-for="(item, index) of tableData" :key="item[id]" class="table-row">
        <!-- 头部 start -->
        <div class="table-row-header">
          <div class="header-content">
            <div class="content-left">
              {{ `${index + 1}# ${item.product_title}` }}
            </div>
            <div>
              <span class="label">总件数：</span
              >{{ item.product_number }}&nbsp;&nbsp;/&nbsp;
              <span class="label">等级：</span
              >{{ item.product_grade }}&nbsp;&nbsp;/&nbsp;
              <span class="label">规格：</span>{{ item.product_spec }}
            </div>
          </div>
        </div>
        <!-- 头部 end -->

        <!-- 右侧打印按钮 start -->
        <div class="print-btn" @click="onHandlePrint(item)">打印标签</div>
        <!-- 右侧打印按钮 end -->
      </div>
    </div>
    <!-- table end -->
  </section>
</template>

<script>
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id: "product_id",
    };
  },
  computed: {},
  methods: {
    /**
     * 打印标签
     */
    onHandlePrint(data) {
      this.$emit("on-handle-print", data);
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid $border-color;
.modules-wrap {
  .table {
    &-row {
      display: flex;
      align-content: center;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      &-header {
        display: flex;
        flex: 1;
        height: 56px;
        padding: 0 20px;
        line-height: 56px;
        font-size: 20px;
        border: $border;
        color: $theme-color;
        background-color: #fbfdf9;
        .header-content {
          flex: 1;
          display: flex;
          gap: 50px;
          .content-left {
            width: 400px;
          }
          .label {
            color: $border-color;
          }
        }
      }
      .print-btn {
        position: relative;
        top: 6px;
        height: 40px;
        padding: 0 10px;
        line-height: 40px;
        border-radius: 4px;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        background-color: #fa884c;
        cursor: pointer;
      }
    }
  }
}
</style>
