var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "打印份数",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c("div", { staticClass: "goods-wrap" }, [
            _c("div", { staticClass: "child" }, [
              _c("span", [_vm._v("您将打印")]),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.productName)),
              ]),
            ]),
            _c("div", { staticClass: "child" }, [
              _c("span", [_vm._v("货号：")]),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.productSku)),
              ]),
            ]),
          ]),
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: { model: _vm.formData, rules: _vm.formRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打印份数：", prop: "print_num" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入打印份数",
                      maxlength: "5",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.print_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "print_num", $$v)
                      },
                      expression: "formData.print_num",
                    },
                  }),
                  _vm._v(" 份 "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("确认打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }