<template>
  <el-dialog
    title="打印份数"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <!-- 商品信息 start -->
      <div class="goods-wrap">
        <div class="child">
          <span>您将打印</span><span class="name">{{ productName }}</span>
        </div>
        <div class="child">
          <span>货号：</span><span class="name">{{ productSku }}</span>
        </div>
      </div>
      <!-- 商品信息 end -->

      <el-form :model="formData" ref="refForm" :rules="formRules">
        <el-form-item label="打印份数：" prop="print_num">
          <el-input
            v-model="formData.print_num"
            placeholder="输入打印份数"
            maxlength="5"
            clearable
          >
          </el-input>
          份
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确认打印</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { validInteger } from "@/utils/tools/validate";
import { postPrintOfOrder } from "@/api/sheet-to-review/index";

export default {
  name: "PrintCountDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      goodsDetails: {}, // 当前商品信息
      productName: "", // 商品名
      productSku: "", // 货号
      formData: {
        supplier_order_no: "", // 供货单编号
        print_num: "", // 打印份数
        window_id: "", // 打印窗口ID
        sku_code: "", // 商品sku_code
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      formRules: {
        print_num: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入打印份数！"));
              }
              if (!Number(value) || validInteger(value)) {
                callback(new Error("请输入大于0的整数！"));
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      data && this.setFormData(data);
    },
    /**
     * 回显信息
     */
    setFormData(data) {
      const {
        supplierOrder,
        sku_code,
        product_title,
        windowId,
        product_number,
      } = data;
      let extPIndex = product_title.lastIndexOf("【");
      this.productName = product_title.substring(0, extPIndex); // 取出文件名
      this.productSku = sku_code;
      this.formData.supplier_order_no = supplierOrder;
      this.formData.print_num = product_number;
      this.formData.window_id = windowId;
      this.formData.sku_code = sku_code;
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.ajaxPostPrintOfOrder();
      });
    },
    /**
     * 打印标签
     */
    async ajaxPostPrintOfOrder() {
      try {
        const params = {
          ...this.formData,
        };
        const { data } = await postPrintOfOrder(params);
        console.log("ajax postPrintOfOrder data", data);
        this.$message.success("打印成功");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postPrintOfOrder err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .goods-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 550;
    .name {
      padding-left: 5px;
      color: $danger-color;
    }
  }
  /deep/ .el-form {
    margin-left: 120px;
    &-item__error {
      left: 110px;
    }
  }
  .el-input {
    width: 300px;
  }
}
</style>
