<template>
  <el-dialog
    title="打印窗口"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents" v-loading="dialogLoading">
      <el-form :model="formData" ref="refForm" :rules="formRules">
        <el-form-item label="打印窗口：" prop="windowId">
          <el-select v-model="formData.windowId" placeholder="选择打印窗口">
            <el-option
              :key="index"
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in windowList"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "BSelectWindows",
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogLoading: true,
      windowList: [], // 窗口列表
      formData: {
        windowId: "", // 打印窗口
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      prePageParams: {}, // 跳转过来带的参数
      formRules: {
        windowId: [
          { required: true, message: "请选择打印窗口", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      this.ajaxGetRoomWindowsList();
      this.prePageParams = data || {};
    },
    /**
     * 打印窗口列表
     */
    async ajaxGetRoomWindowsList() {
      this.dialogLoading = true;
      try {
        const { data } = await this.$api.logistics.roomWindows();
        this.windowList = data;
      } catch (err) {
        console.log("ajax $api.logistics.roomWindows err", err);
      } finally {
        this.dialogLoading = false;
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        // 将跳转前的参数带回
        const params = {
          windowId: this.formData.windowId,
          prePageParams:
            Object.values(this.prePageParams).length && this.prePageParams,
        };
        this.$emit("on-change-print-windows", params);
        this.onHandleClose();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  /deep/ .el-form {
    margin-left: 100px;
    &-item__error {
      left: 110px;
    }
  }
  .el-select {
    width: 300px;
  }
}
</style>
