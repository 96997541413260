var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "打印窗口",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
          staticClass: "contents",
        },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: { model: _vm.formData, rules: _vm.formRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打印窗口：", prop: "windowId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择打印窗口" },
                      model: {
                        value: _vm.formData.windowId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "windowId", $$v)
                        },
                        expression: "formData.windowId",
                      },
                    },
                    _vm._l(_vm.windowList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }