var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "modules-wrap" }, [
    _c(
      "div",
      { staticClass: "table" },
      _vm._l(_vm.tableData, function (item, index) {
        return _c("div", { key: item[_vm.id], staticClass: "table-row" }, [
          _c("div", { staticClass: "table-row-header" }, [
            _c("div", { staticClass: "header-content" }, [
              _c("div", { staticClass: "content-left" }, [
                _vm._v(
                  " " + _vm._s(`${index + 1}# ${item.product_title}`) + " "
                ),
              ]),
              _c("div", [
                _c("span", { staticClass: "label" }, [_vm._v("总件数：")]),
                _vm._v(_vm._s(item.product_number) + "  /  "),
                _c("span", { staticClass: "label" }, [_vm._v("等级：")]),
                _vm._v(_vm._s(item.product_grade) + "  /  "),
                _c("span", { staticClass: "label" }, [_vm._v("规格：")]),
                _vm._v(_vm._s(item.product_spec) + " "),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "print-btn",
              on: {
                click: function ($event) {
                  return _vm.onHandlePrint(item)
                },
              },
            },
            [_vm._v("打印标签")]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }